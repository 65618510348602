import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Downloader from "../components/downloader"
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Faqs from "../components/faqs"
import HowTo from "../components/howTo"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("Online Instagram Video Downloader")} description={t("Download Instagram Videos in HD and Audio Formats")} />
      <Downloader title={t("Online Free Instagram Video Downloader")} description={t("Download Instagram Videos in HD and Audio Formats")} />
      <Container>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Instagram Video Downloader")}</h2>
            <p>{t("With this Snapzy, saving Instagram videos has never been easier.")} <Link to="/instagram-video-downloader/">{t("Instagram Video Downloader")}</Link> {t("helps you to avoid all of the hassles associated with saving videos by downloading them from this platform. The account from which you're extracting the video, on the other hand, must be public. Otherwise, this online downloader will not be able to fetch or download videos from the private account.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("How to Downlaod Instagram Videos Online")}?</h2>
            <p>{t("There are a few different ways that you can download videos from Instagram")}:</p>
            <ol>
              <li>{t("Use a website that allows you to download Instagram videos. There are a number of websites that allow you to enter the URL of the Instagram video you want to download, and then download the video in a format of your choice (such as MP4). Some examples of websites that you can use to download Instagram videos include FastSave, Video Downloader for Instagram, InstaSave, and")} <Link to="/instagram-video-downloader/">{t("Snapzy Instagram Video Downloader")}</Link>.</li>
              <li>{t("Use a screen recording app to record the Instagram video as you are watching it. This will allow you to capture the video without any watermarks or other overlaid graphics, as they will not be present in the recording. You can use a screen recording app such as AZ Screen Recorder or DU Recorder for Android, or QuickTime Player for macOS.")}</li>
              <li>{t("Use a video downloader app to download the Instagram video directly to your device. There are a number of apps that allow you to download Instagram videos directly to your phone or tablet. Some examples of these apps include VidMate and Snaptube for Android, and MyMedia and Video Downloader for iOS.")}</li>
            </ol>
            <p>{t("It's important to note that downloading videos from Instagram without the permission of the copyright holder may be a violation of the terms of service for the platform, and could potentially be illegal. You should only download Instagram videos for personal, non-commercial use, and you should always respect the intellectual property rights of the content creators.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Questions About Instagram Reels Video Download")}</h2>
            <h5>{t("Are Instagram video downloaders legal")}?</h5>
            <p>{t("It's important to note that downloading videos from Instagram without the permission of the copyright holder may be a violation of the terms of service for the platform, and could potentially be illegal. You should only download Instagram videos for personal, non-commercial use, and you should always respect the intellectual property rights of the content creators.")}</p>
            <h5>{t("How do Instagram video downloaders work")}?</h5>
            <p>{t("Instagram video downloaders work by allowing you to enter the URL of the Instagram video you want to download, and then downloading the video in a format of your choice (such as MP4). Some Instagram video downloaders are websites that you can use in your web browser, while others are apps that you can download and install on your device.")}</p>
            <h5>{t("Can I download Instagram videos in high quality")}?</h5>
            <p>{t("Yes, many Instagram video downloaders allow you to download videos in high quality. Some Instagram video downloaders may offer a range of quality options, such as 360p, 480p, 720p, or 1080p. You can choose the quality level that best suits your needs.")}</p>
            <h5>{t("Is it possible to download Instagram videos on a computer")}?</h5>
            <p>{t("Yes, it is possible to download Instagram videos on a computer. You can use a website that allows you to download Instagram videos, or you can use a screen recording app to record the video as you are watching it. You can also use a video downloader app that is compatible with your computer's operating system, such as 4K Video Downloader for Windows or Mac.")}</p>
            <h5>{t("Can I download Instagram videos with the watermark intact")}?</h5>
            <p>{t("Yes, it is possible to download Instagram videos with the watermark intact. However, if you want to download the video without the watermark, you can try using a screen recording app to record the video as you are watching it, or you can use a video downloader app to download the video directly to your device.")}</p>
            <h5>{t("How to download instagram videos without app")}?</h5>
            <p>{t("You don't need to install any app on your mobile to download video Instagram. Snapzy works as a universal tool for all devices without any installation.")}</p>
            <h5>{t("How to download Instagram videos on iPhone or android")}?</h5>
            <p>{t("Instagram video download online (Snapzy) can be used on android and iPhone mobiles and you can easily save your favourite Instagram reel videos in your local storage if you own the right to the video.")}</p>
            <h5>{t("How to download private Instagram videos")}?</h5>
            <p>{t("There is no way to download private Instagram reels without giving your account to some spammy software which can risk your account as well. Private Instagram videos can only be seen by the followers' account and if you need to download a private Instagram reel, you have to ask the account owner for this.")}</p>
          </Col>
        </Row>
      </Container>
      <HowTo />
      <Faqs />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
